exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-form-success-tsx": () => import("./../../../src/pages/form-success.tsx" /* webpackChunkName: "component---src-pages-form-success-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-inquiry-tsx": () => import("./../../../src/pages/inquiry.tsx" /* webpackChunkName: "component---src-pages-inquiry-tsx" */),
  "component---src-pages-license-tsx": () => import("./../../../src/pages/license.tsx" /* webpackChunkName: "component---src-pages-license-tsx" */),
  "component---src-pages-magento-2-augmented-reality-and-3-d-tsx": () => import("./../../../src/pages/magento2-augmented-reality-and-3d.tsx" /* webpackChunkName: "component---src-pages-magento-2-augmented-reality-and-3-d-tsx" */),
  "component---src-pages-magento-2-consulting-taipei-tsx": () => import("./../../../src/pages/magento2-consulting-taipei.tsx" /* webpackChunkName: "component---src-pages-magento-2-consulting-taipei-tsx" */),
  "component---src-pages-offer-magento-2-ecommerce-consulting-tsx": () => import("./../../../src/pages/offer/magento2-ecommerce-consulting.tsx" /* webpackChunkName: "component---src-pages-offer-magento-2-ecommerce-consulting-tsx" */),
  "component---src-pages-offer-magento-2-extensions-development-tsx": () => import("./../../../src/pages/offer/magento-2-extensions-development.tsx" /* webpackChunkName: "component---src-pages-offer-magento-2-extensions-development-tsx" */),
  "component---src-pages-offer-online-store-optimizations-tsx": () => import("./../../../src/pages/offer/online-store-optimizations.tsx" /* webpackChunkName: "component---src-pages-offer-online-store-optimizations-tsx" */),
  "component---src-pages-offer-web-development-tsx": () => import("./../../../src/pages/offer/web-development.tsx" /* webpackChunkName: "component---src-pages-offer-web-development-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-promo-magento-2-extensions-checkout-addons-tsx": () => import("./../../../src/pages/promo/magento-2-extensions/checkout-addons.tsx" /* webpackChunkName: "component---src-pages-promo-magento-2-extensions-checkout-addons-tsx" */),
  "component---src-pages-promo-wordpress-plugins-tiqr-authentication-tsx": () => import("./../../../src/pages/promo/wordpress-plugins/tiqr-authentication.tsx" /* webpackChunkName: "component---src-pages-promo-wordpress-plugins-tiqr-authentication-tsx" */),
  "component---src-pages-tools-can-i-run-web-ar-tsx": () => import("./../../../src/pages/tools/can-i-run-web-ar.tsx" /* webpackChunkName: "component---src-pages-tools-can-i-run-web-ar-tsx" */),
  "component---src-pages-tools-color-palette-generator-tsx": () => import("./../../../src/pages/tools/color-palette-generator.tsx" /* webpackChunkName: "component---src-pages-tools-color-palette-generator-tsx" */),
  "component---src-pages-tools-seo-page-check-tsx": () => import("./../../../src/pages/tools/seo-page-check.tsx" /* webpackChunkName: "component---src-pages-tools-seo-page-check-tsx" */),
  "component---src-pages-tools-url-to-qr-code-generator-tsx": () => import("./../../../src/pages/tools/url-to-qr-code-generator.tsx" /* webpackChunkName: "component---src-pages-tools-url-to-qr-code-generator-tsx" */),
  "component---src-templates-post-list-tsx": () => import("./../../../src/templates/post-list.tsx" /* webpackChunkName: "component---src-templates-post-list-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */),
  "component---src-templates-tags-list-tsx": () => import("./../../../src/templates/tags-list.tsx" /* webpackChunkName: "component---src-templates-tags-list-tsx" */),
  "component---src-templates-tags-tsx": () => import("./../../../src/templates/tags.tsx" /* webpackChunkName: "component---src-templates-tags-tsx" */)
}

